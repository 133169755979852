<script setup>
  const top = useTop()

  const scrollTop = () => {
    window.scrollTo({ behavior: 'smooth', top: 0 })
  }

  const socialNetworks = [
    { class: 'hover:text-linkedin', icon: 'fa-brands fa-linkedin-in', to: 'https://www.linkedin.com/company/profilpublic' },
    { class: 'hover:text-twitter', icon: 'fa-brands fa-twitter', to: 'https://twitter.com/_profilpublic' }
  ]
</script>

<template>
  <footer ref="footer">
    <div class="bg-tarawera-900">
      <div class="mx-auto flex max-w-screen-xl flex-col justify-center md:flex-row">
        <div class="flex flex-1 flex-wrap items-start py-4 text-white">
          <FooterNewsletter class="w-full p-4 sm:flex-1 lg:order-last" />

          <div class="w-full space-x-8 p-4 md:hidden">
            <NuxtLink class="duration-300" v-for="link in socialNetworks" :class="link.class" target="_blank" :to="link.to">
              <FontAwesomeIcon :icon="link.icon" fixedWidth size="lg" />
            </NuxtLink>
          </div>

          <div class="p-4 sm:order-first lg:order-2">
            <ul class="space-y-1 sm:space-y-2">
              <li
                v-for="link in [
                  { text: 'Qui sommes-nous', to: '/employeurs/profil-public' },
                  { target: '_blank', text: 'Foire aux questions', to: 'https://profil-public.zendesk.com/hc/fr' },
                  { text: 'Nos services', to: '/nos-services' },
                  { text: 'Nos partenaires', to: '/nos-partenaires' }
                ]"
              >
                <NuxtLink class="group duration-300 hover:text-viking-500" :target="link.target" :to="link.to">
                  <FontAwesomeIcon
                    class="text-viking-400 duration-300 group-hover:text-inherit"
                    fixedWidth
                    icon="fa-solid fa-angle-right"
                    size="2xs"
                  />
                  {{ link.text }}
                </NuxtLink>
              </li>
            </ul>
          </div>

          <div class="flex w-full justify-center p-4 lg:order-first lg:w-auto">
            <div class="relative pb-4 pl-14 pr-10 pt-4 font-special text-2xl font-bold">
              <NuxtImg class="absolute -top-5 left-0 mx-auto aspect-square h-20 opacity-50" src="/images/quote.svg" alt="Quote" />
              <NuxtImg class="absolute bottom-0 right-0 h-12 w-12 rounded-full" src="/images/logo-pp-400x400.webp" alt="Logo Profil Public" />
              <div class="relative">Réenchantons le<br />recrutement du<br />secteur public&nbsp;!</div>
            </div>
          </div>
        </div>

        <div class="ml-4 hidden flex-col items-center justify-center space-y-6 bg-white p-4 md:flex">
          <NuxtLink class="duration-300" v-for="link in socialNetworks" :class="link.class" target="_blank" :to="link.to">
            <FontAwesomeIcon :icon="link.icon" fixedWidth size="lg" />
          </NuxtLink>
        </div>
      </div>
    </div>

    <div class="bg-viking-400">
      <div class="mx-auto flex max-w-screen-xl flex-col flex-wrap items-center justify-center px-4 py-2 text-sm lg:flex-row">
        <div>©&nbsp;Profil Public. Tous droits réservés</div>
        <div class="mx-4 hidden text-viking-500 lg:inline">-</div>
        <div class="mt-1 flex flex-col items-center justify-center sm:flex-row sm:space-x-4 lg:mt-0">
          <NuxtLink class="duration-300 hover:text-white" to="/mentions-legales">Mentions légales</NuxtLink>
          <div class="hidden text-viking-500 sm:inline">-</div>
          <NuxtLink class="duration-300 hover:text-white" to="/conditions-generales">Conditions générales</NuxtLink>
          <div class="hidden text-viking-500 sm:inline">-</div>
          <NuxtLink class="duration-300 hover:text-white" to="/politique-de-donnees">Politique de données</NuxtLink>
        </div>
      </div>
    </div>

    <transition enterFromClass="opacity-0" enterToClass="opacity-100" leaveFromClass="opacity-100" leaveToClass="opacity-0">
      <div class="fixed bottom-16 right-2 duration-500 md:right-4" v-if="!top">
        <button
          class="hidden h-8 w-8 items-center justify-center rounded bg-viking-400 text-white shadow-lg backdrop-blur duration-300 hover:bg-viking-500 md:flex"
          @click="scrollTop"
        >
          <FontAwesomeIcon icon="fa-solid fa-chevron-up" />
        </button>
      </div>
    </transition>
  </footer>
</template>
