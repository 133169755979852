<script setup>
  const dev = process.dev

  const debugMode = useDebugMode()

  const top = useTop()

  const open = ref(false)
</script>

<template>
  <div class="absolute inset-x-0 top-full shadow duration-500" :class="[dev ? 'block' : 'hidden', open ? 'translate-y-0' : '-translate-y-full']">
    <div class="bg-purple-50/80 px-4 py-8 text-black" id="dev"></div>
    <div class="pointer-events-none absolute inset-x-0 border-t-2 border-purple-500 font-mono">
      <div class="mx-auto max-w-screen-xl px-4">
        <div class="ml-20 flex duration-500" :class="[top ? 'md:ml-24' : 'md:ml-20']">
          <button
            class="pointer-events-auto rounded-b bg-gradient-to-b from-purple-500 px-2 py-px text-xs text-white shadow backdrop-blur duration-300 hover:pt-1"
            tabindex="-1"
            :class="[debugMode ? 'to-pink-500/90' : 'to-pink-500/50 hover:to-pink-600/50']"
            @click="open = !open"
          >
            <span class="xs:hidden">2xs</span>
            <span class="hidden xs:max-sm:inline">xs</span>
            <span class="hidden sm:max-md:inline">sm</span>
            <span class="hidden md:max-lg:inline">md</span>
            <span class="hidden lg:max-xl:inline">lg</span>
            <span class="hidden xl:max-2xl:inline">xl</span>
            <span class="hidden 2xl:inline">2xl</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
