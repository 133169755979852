<script setup>
  import * as yup from 'yup'
  import { Form } from 'vee-validate'
  import { notify } from 'notiwind'
  import { handleError } from '@/utils/error'
  import sleep from '@/utils/sleep'

  const route = useRoute()
  const { create } = useStrapi4()

  const subscribe = {
    onSubmit: async (values, actions) => {
      if (subscribe.submitting.value) return
      subscribe.submitting.value = true
      try {
        const res = await create('newsletters', values)
        actions.resetForm()
        notify({
          group: 'main',
          type: 'success',
          title: 'Inscription validée',
          text: res.alreadySubscribed
            ? 'Votre adresse mail est déjà inscrite à la newsletter.'
            : 'Vous recevrez par email le lien pour confirmer votre inscription d’ici quelques instants.<br />N’oubliez pas de vérifier dans vos spams si besoin.'
        })
      } catch (error) {
        handleError(error, true)
      }
      subscribe.submitting.value = false
    },
    schema: yup.object({
      email: yup.string().required('${label} est requis').email('${label} est invalide').label('Email')
    }),
    submitting: ref(false)
  }

  onMounted(async () => {
    if (route.hash === '#newsletterOK') {
      await sleep(100)
      notify({
        group: 'main',
        type: 'success',
        title: 'Inscription confirmée',
        text: 'Vous recevrez désormais la newsletter Profil Public chaque semaine.'
      })
    } else if (route.hash === '#newsletterKO') {
      await sleep(100)
      handleError({ error: 'newsletter' }, 'Votre lien de confirmation est invalide.')
    }
  })
</script>

<template>
  <div>
    <div class="font-special text-base font-bold">LA NEWSLETTER D’INTÉRÊT GÉNÉRAL</div>
    <div class="mt-4">Tous les samedis, des conseils & articles secteur public dans votre boîte mail.</div>
    <Form class="mt-2 flex items-start space-x-1" v-slot="{ errors }" :validationSchema="subscribe.schema" @submit="subscribe.onSubmit">
      <FormField class="flex-1" :error="errors.email" name="email" placeholder="Email" required type="email" />
      <Button :disabled="subscribe.submitting.value" type="submit">
        <FontAwesomeIcon icon="fa-solid fa-check" />
      </Button>
    </Form>
  </div>
</template>
