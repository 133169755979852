<script setup>
  import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
  import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'

  const ats = useAts()
  const { find } = useStrapi4()
  const client = useStrapiClient()

  const notifications = reactive({
    ...(await useLazyAsyncData(
      'latest-notifications-new',
      async () => {
        const res = await find('notifications', {
          fields: '*',
          filters: {
            employer: ats.value.employer?.id
          },
          populate: {
            comment: {
              fields: ['text'],
              populate: {
                application: {
                  populate: {
                    candidate: {
                      fields: ['firstName', 'lastName']
                    }
                  }
                },
                author: {
                  fields: ['firstName', 'lastName']
                },
                job: { fields: ['title'] }
              }
            },
            application: {
              fields: ['id'],
              populate: {
                candidate: {
                  fields: ['firstName', 'lastName'],
                  populate: {
                    user: {
                      fields: ['firstName', 'lastName'],
                      avatar: { fields: ['url'] }
                    }
                  }
                },
                job: { fields: ['title'] }
              }
            },
            job: {
              fields: ['id', 'title', 'expiresAt']
            }
          },
          pagination: { limit: 50, start: 0, withCount: true },
          sort: { createdAt: 'desc' }
        })
        return res.data
      },
      {
        default: () => [],
        watch: [ats]
      }
    ))
  })

  const unreadNotifications = ref(false)
  watch(notifications, () => {
    unreadNotifications.value = notifications.data.some(notification => !notification.read)
  })

  const showOnlyUnread = ref(false)
  let shownNotifications = computed(() => {
    if (showOnlyUnread.value) {
      return notifications.data.filter(notification => !notification.read)
    } else {
      return notifications.data
    }
  })

  const readAll = async () => {
    for (const notification of notifications.data) {
      notification.read = true
    }
    client(`/notifications/read-many`, {
      method: 'PUT',
      body: {
        data: { ids: notifications.data.map(notification => notification.id) }
      }
    })
  }
</script>

<template>
  <Menu class="relative" v-slot="{ close }" as="div">
    <Tooltip content="Notifications">
      <MenuButton class="relative cursor-pointer duration-300 hover:text-viking-500 focus-visible:text-viking-500">
        <Icon name="bell" size="lg" />
        <div class="absolute -right-1 bottom-0 h-2 w-2 rounded-full bg-viking-400" v-if="unreadNotifications" />
      </MenuButton>
    </Tooltip>

    <transition
      enter-active-class="transition duration-75 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        class="duration fixed left-4 right-4 top-12 z-50 flex max-h-[600px] flex-col rounded border bg-white font-special text-tarawera-900 md:absolute md:left-auto md:right-0 md:top-8 md:w-auto"
      >
        <div class="flex flex-row items-center space-x-2 px-4 pt-4">
          <p class="font-bold">Notifications</p>
          <SwitchGroup class="space-x-2 pl-4" as="div">
            <SwitchLabel class="cursor-pointer font-main text-xs" :class="showOnlyUnread ? 'text-tarawera-900' : 'text-zinc-400'"
              >Afficher uniquement les non lues</SwitchLabel
            >
            <Switch
              class="relative inline-flex h-2 w-4 shrink-0 items-center rounded-full outline-none duration-300 focus-visible:ring focus-visible:ring-viking-400"
              v-model="showOnlyUnread"
              :class="showOnlyUnread ? 'bg-viking-400' : 'bg-zinc-300'"
            >
              <span class="inline-block h-2 w-2 rounded-full bg-white duration-300" :class="showOnlyUnread ? 'translate-x-2' : 'translate-x-0'" />
            </Switch>
          </SwitchGroup>
          <Menu>
            <MenuButton class="flex w-4 cursor-pointer justify-center">
              <Icon name="gear" size="sm" />
            </MenuButton>
            <transition
              enter-active-class="transition duration-75 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <MenuItems class="absolute right-6 top-10 z-20 flex flex-col rounded-lg border border-gray-300 bg-white py-1 text-xs shadow-lg">
                <MenuItem class="cursor-pointer px-2 py-1 hover:bg-gray-100" as="div" @click="readAll">Tout marquer comme lu</MenuItem>
                <MenuItem class="cursor-pointer hover:bg-gray-100" as="div">
                  <NuxtLink to="/mon-profil/notifications" @click="close">
                    <div class="px-2 py-1">Paramétrer les notifications</div>
                  </NuxtLink>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>

        <div class="mx-auto mt-4 h-[1px] w-11/12 bg-zinc-300"></div>

        <div class="mt-1 flex flex-col overflow-y-scroll">
          <div class="py-4 text-center text-sm text-zinc-400" v-if="notifications.status === 'pending'">Chargement...</div>
          <div class="py-4 text-center text-sm text-zinc-400" v-else-if="shownNotifications.length === 0">Aucune notification</div>
          <transition-group leave-active-class="absolute opacity-0 transition-opacity duration-0">
            <MenuItem
              class="transition-all duration-500"
              v-for="notification in shownNotifications"
              v-slot="{ close }"
              :key="notification.id"
              as="div"
              :class="{
                'z-10': !notification.read
              }"
            >
              <NotificationsItem :notification="notification" @click="close" />
            </MenuItem>
          </transition-group>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>
