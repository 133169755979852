<script setup>
  const props = defineProps({
    duration: { default: 2000, type: Number },
    throttle: { default: 200, type: Number }
  })

  const data = reactive({
    canSucceed: true,
    percent: 0,
    show: false
  })

  let _timer = null
  let _throttle = null
  let _cut

  function clear() {
    if (_timer) clearInterval(_timer)
    if (_throttle) clearTimeout(_throttle)
    _timer = null
  }

  function start() {
    clear()
    data.percent = 0
    data.canSucceed = true

    if (props.throttle) _throttle = setTimeout(startTimer, props.throttle)
    else startTimer()
  }

  function set(num) {
    data.show = true
    data.canSucceed = true
    data.percent = Math.min(100, Math.max(0, Math.floor(num)))
  }

  function increase(num) {
    data.percent = Math.min(100, Math.floor(data.percent + num))
  }

  function decrease(num) {
    data.percent = Math.max(0, Math.floor(data.percent - num))
  }

  function pause() {
    clearInterval(_timer)
  }

  function resume() {
    startTimer()
  }

  function finish() {
    data.percent = 100
    hide()
  }

  function hide() {
    clear()
    setTimeout(() => {
      data.show = false
      setTimeout(() => {
        data.percent = 0
      }, 400)
    }, 500)
  }

  function startTimer() {
    data.show = true
    _cut = 10000 / Math.floor(props.duration)
    _timer = setInterval(() => {
      increase(_cut)
    }, 100)
  }

  const nuxtApp = useNuxtApp()

  nuxtApp.hook('page:start', start)
  nuxtApp.hook('page:finish', finish)

  onBeforeUnmount(() => clear)
</script>

<template>
  <div class="absolute inset-x-0 top-0 h-1" :class="[data.show ? 'opacity-100' : 'opacity-0']">
    <div class="h-full bg-gradient-to-r from-viking-400 to-viking-500 duration-100" :style="{ width: `${data.percent}%` }" />
  </div>
</template>
